import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClimbingLoader } from 'theme/Loader/Loader';
import { ROUTES } from 'utils/constants';

const AuthGuard = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      navigate('/signin');
    } else {
      navigate(ROUTES.REPOS);
      setIsLoading(false);
    }
  }, [navigate]);

  return isLoading ? <ClimbingLoader /> : <>{children}</>;
};

export default AuthGuard;
