import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3A479D' // Your main color
    },
    secondary: {
      main: '#F3F3F3' // A secondary color, if desired
    },
    background: {
      default: '#ffffff' // Set default background color for the entire app
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#ffffff !important' // Sets body background color to white
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
        outlined: {
          background: 'transparent', // Ensures outlined buttons are transparent
          borderColor: '#4C4BC6' // Optional: use the primary color for borders
        }
      }
    }
  }
});

export default theme;
