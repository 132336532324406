// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth, GithubAuthProvider, signInWithPopup } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAjJl6XNiBpFbrl8upoHJUZwp0BtCAxmEg',
  authDomain: 'github-llm-actions-dev-ec.firebaseapp.com',
  projectId: 'github-llm-actions-dev-ec',
  storageBucket: 'github-llm-actions-dev-ec.firebasestorage.app',
  messagingSenderId: '149958201999',
  appId: '1:149958201999:web:fcc72e8df7f7f6092e16fa',
  measurementId: 'G-KNG7TE7ZCV',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GithubAuthProvider();
provider.addScope('repo'); // Access to repositories
provider.addScope('read:user'); // Access to user data

export { auth, provider, signInWithPopup };
