import { lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import Loadable from 'theme/Loader/Loadable';
import AuthGuard from 'theme/guards/AuthGuard';
import AuthRedirectGuard from 'theme/guards/AuthRedirectGuard';
import { ROUTES } from 'utils/constants';
const MainTheme = Loadable(lazy(() => import('theme/mainTheme/MainTheme')));
const Repos = Loadable(lazy(() => import('pages/repos')));
const SignIn = Loadable(lazy(() => import('pages/auth/SignIn')));

const Routes = () => {
  return useRoutes([
    {
      path: ROUTES.HOME,
      element: (
        <AuthGuard>
          <MainTheme />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={ROUTES.REPOS} />, index: true },
        { path: ROUTES.REPOS, element: <Repos /> },
      ],
    },
    {
      path: ROUTES.SIGNIN,
      element: (
        <AuthRedirectGuard>
          <SignIn />
        </AuthRedirectGuard>
      ),
    },
    { path: '*', element: <Navigate to={ROUTES.HOME} /> },
  ]);
};

export default Routes;
