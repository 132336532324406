import React from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES } from 'utils/constants';

const AuthRedirectGuard = ({ children }) => {
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken) {
    return <Navigate to={ROUTES.REPOS} replace />;
  }

  return <>{children}</>;
};

export default AuthRedirectGuard;
