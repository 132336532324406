import { signOut } from 'firebase/auth';
import { auth } from 'firebaseConfig';
import { clearUserDetail } from 'store/reducers/userSlice';
import { dispatch } from 'store/store';

export const storeUserDataLocalStorage = user => {
  if (user) {
    localStorage.setItem('access_token', user.token);
    localStorage.setItem('user', JSON.stringify(user));
  }
};

export const handleLogout = async () => {
  await signOut(auth);
  dispatch(clearUserDetail());
  localStorage.clear();
  window.location.reload();
};
